<template>
    <v-container>
        <v-row class="text-center" justify="center" style="min-height: 70vh;">
            <v-col cols="12" md="10" lg="8" align-self="center" class="text-center" v-if="!user.data.activated || loading">
                <v-card class="rounded-xl pa-4">
                    <h1>Aktivierungscode eingeben</h1>
                    <span>Um deinen Account zu aktivieren gib bitte den Aktivierungscode ein, der einem deiner Erziehungsberechtigten zugeschickt wurde:</span>
                    <v-row justify="center" class="mt-5">
                        <v-col cols="10" md="8" lg="6">
                            <v-otp-input
                                length="5"
                                v-model="code"
                                type="number"
                                dark
                                :disabled="loading"
                                @change="activateAccount()"
                            >
                            </v-otp-input>
                        </v-col>
                        <v-col cols="auto" align-self="center" v-if="error">
                            <v-btn icon @click="loading = false
                            code = ''
                            error = false" v-if="error"><font-awesome-icon icon="fa-light fa-arrow-rotate-right" size="2x" /></v-btn>
                        </v-col>
                        <v-col cols="12" class="text-center" align-self="center">
                            <h3 v-if="error"><font-awesome-icon color="red" icon="fa-solid fa-xmark" class="mr-2" /> {{errortext}}</h3>
                            <h3 v-if="activated"><font-awesome-icon color="green" icon="fa-solid fa-check" class="mr-2" /> Aktivierungscode ist gültig.</h3>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" md="10" lg="8" align-self="center" class="text-center" v-if="user.data.activated && !loading">
                <v-card class="rounded-xl pa-4">
                    <font-awesome-icon color="green" icon="fa-duotone fa-circle-check" size="9x" />
                    <h1 class="mt-5">Account wurde erfolgreich aktiviert</h1>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { doc, updateDoc, deleteDoc } from 'firebase/firestore'

export default {
    name: 'Aktivierung',
    data() {
        return {
            code: '',
            activated: false,
            loading: false,
            error: false,
            errortext: ''
        }
    },
    computed: {
        ...mapGetters({
            user: 'user',
            vereine: 'vereine',
            db: 'db',
            sportarten: 'sportarten',
            sportangebote: 'sportangebote',
        }),
    },
    methods: {
        activateAccount(){
            this.loading = true
            if(this.code == this.user.data.authCode){
                updateDoc(doc(this.db, '/User/'+this.user.uid), {
                    activated: true
                }).then(() => {
                    this.activated = true
                    setTimeout(() => {
                        this.loading = false
                    }, 4000)
                })
            }
            else {
                this.error = true
                this.errortext = "Der eingegebene Aktivierungscode ist ungültig."
            }
        }
    }
}
</script>